import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLoad from "react-lazyload"

const WorkshopsPage = ({ data }) => {
  const allSessions = data.allDatoCmsSession.nodes
  const sortedSessions = [...allSessions].sort((a, b) => {
    console.log(a, b)
    if (!a.priority) return 1
    if (!b.priority) return -1
    return a.priority - b.priority
  })
  const {
    heading = "Workshops & Ceremonies",
    subheading,
  } = data.datoCmsSessionsPage
  return (
    <Layout fillMenu>
      <SEO title={heading} />

      <div class="container mt-5 is-small is-text-center">
        <h1 class="heading mb-3">{heading}</h1>
        <p class="subheading">{subheading}</p>
      </div>

      <div class="container is-padded">
        <div class="sessions">
          {sortedSessions.map((session, index) => (
            <LazyLoad offset={100}>
              <div
                class="session"
                style={{
                  backgroundImage: `url(${session.mainImage.url}?w=600&q=27&auto=compress)`,
                }}
              >
                <div>
                  <h2 class="h2">{session.heading}</h2>
                  <p class="p mt-2">{session.subheading}</p>
                  {session.showReadMore && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={session.readMoreLink}
                      class="button is-small mt-3"
                    >
                      Read more
                    </a>
                  )}
                </div>
              </div>
            </LazyLoad>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default WorkshopsPage

export const query = graphql`
  query Workshops {
    datoCmsSessionsPage {
      heading
      subheading
      mainImage {
        url
      }
    }
    allDatoCmsSession {
      nodes {
        readMoreLink
        heading
        id
        showReadMore
        subheading
        mainImage {
          url
        }
        priority
      }
    }
  }
`
